/* @font-face {
    font-family: "Inter";
    src: local("Inter"),
        url("./font/Inter/Inter-Regular.ttf") format("opentype");
    font-weight: normal;
    font-style: normal;
} */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

body {
  font-family: 'Inter', sans-serif !important;
  font-size: 14px !important;
}

.text-right {
  text-align: right;
}

.none {
  display: none;
}

.span-user {
  border-radius: 40px;
  padding: 4px 8px;
  margin-right: 5px;
  max-width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: #dddfe2;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.span-location {
  border-radius: 40px;
  padding: 4px 8px;
  margin-right: 5px;
  display: inline-block;
  background: #dddfe2;
  font-size: 14px;
  font-weight: 400;
  border: none;
}

.span-device_group {
  border: 1px solid;
  border-radius: 4px;
  padding: 3px 5px;
  margin-right: 5px;
  max-width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn-more-user {
  border: none;
  border-radius: 40px;
  padding: 4px 8px;
  margin-right: 5px;
  max-width: 200px;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  background: #dddfe2;
  font-size: 14px;
  font-weight: 400;
}

.btn-open-modal {
  height: 41px;
  width: 85%;
  /* font-size: 14px; */
}

.btn-more-user:hover {
  color: #fff;
  background-color: #1e2ec0;
}

.lable-checkbox {
  width: 100%;
  border: 1px solid #eff2f5;
  height: 36px;
  line-height: 36px;
  border-radius: 4px;
  padding: 0 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.check-all {
  margin-right: 10px;
  height: 1.5rem;
  width: 1.5rem;
}

.display-node {
  display: none;
}

.div-role {
  border-radius: 4px;
  /* border: 1px solid; */
  background: #eff2f5;
}

ul.ant-pagination.ant-table-pagination.ant-table-pagination-right {
  display: none;
}

.btn-move {
  height: 40px;
  border: none;
  background-color: #e0f2ff;
  margin: 0 6px;
  border-radius: 4px;
}

.btn-move i {
  font-size: 24px;
  color: #009ef7;
}

.div-btn-move {
  height: 100%;
  margin: 0;
  align-content: center;
}

.plus-expand {
  cursor: pointer;
  font-size: 15px;
  margin-right: 3px;
}

.div-treeview {
  display: flex;
  align-items: center;
  padding: 10px 10px 10px 15px;
  background-color: #f5f8fa;
  cursor: pointer;
  border-bottom: 1px solid #eff2f5;
}

.flex-1 {
  flex: 1;
}

.div-treeview-ward {
  display: flex;
  align-items: center;
  padding: 10px 6px 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #eff2f5;
}

.div-treeview-ward:hover {
  background-color: rgba(0, 158, 247, 0.1);
  color: #1e2ec0;
  font-weight: 700;
}

.div-treeview-wrapper {
  border: 1px solid #c3c6c9;
  /* border-radius: 4px 0 0 4px; */
  height: 100%;
}

.div-create-device {
  position: absolute;
  padding: 15px;
  bottom: 0;
  width: 100%;
  /* margin: 5px; */
  /* border-radius: 4px; */
  /* border: 1px solid orange; */
  text-align: center;
  border-top: inherit;
  background: #fff;
}

.div-checkbox {
  max-height: 424px;
  overflow: scroll;
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
}

.dropzone:focus {
  border-color: #2196f3;
}

.div-group {
  margin: 6px 0px;
  display: flex;
}

.div-checkbox label {
  cursor: pointer;
}

.VideoInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.VideoInput_input {
  padding: 6px;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

.VideoInput_video {
  display: block;
  margin: 0;
  max-height: 400px;
}

.VideoInput_footer {
  /* background: #eee; */
  background: #fff;
  width: 100%;
  min-height: 36px;
  position: relative;
}

.btn-delete {
  background-color: #f1416c;
  color: white !important;
}

.btn-delete:hover {
  background-color: red;
}

.btn-choose-file {
  border: 1px solid #767676;
  border-radius: 3px;
  z-index: 1;
}

.div-upload-video {
  display: flex;
  padding: 8px;
  align-items: center;
}

#kt_modal_device_notification thead,
#kt_modal_device_notification tbody,
#kt_modal_device_notification tfoot,
#kt_modal_device_notification tr,
#kt_modal_device_notification td,
#kt_modal_device_notification th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border: 1px solid;
}

.optionListContainer {
  background: #fff;
  border-radius: 4px;
  margin-top: 1px;
  position: absolute;
  width: 97% !important;
  z-index: 2;
}

.searchWrapper {
  border: 0 !important;
  padding: 0 !important;
  max-height: 105px;
  overflow-y: auto;
}

/* .header-fixed.toolbar-fixed .toolbar {
    top: 0 !important;
}

.header-fixed .wrapper {
    padding-top: 55px !important;
} */

.icon-loa {
  width: 36px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.icon-loa-hd {
  background-image: src='/media/avatars/icon-loa-hd.png';
}

.icon-loa-td {
  background-image: src='/media/avatars/icon-loa-td.png';
}

.icon-led {
  font-size: 20px;
  color: blue;
}

.div-setting {
  display: none;
  margin-top: -8px;
}

.div-setting:hover {
  display: block;
}

.icon-loa:hover+.div-setting {
  display: block;
}

.icon-led:hover+.div-setting {
  display: block;
}

.mapboxgl-marker:hover {
  z-index: 1;
}

.icon-setting {
  margin-top: 20px;
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
}

.div-lable {
  padding: 10px 10px 10px;
  text-align: center;
}

.custom-1 {
  padding: 30px;
  border: 1px solid #9dd7ff;
  background: #9dd7ff;
  color: #000;
}

.custom-2 {
  padding: 30px;
  border: 1px solid #faf998;
  background: #faf998;
  color: #000;
}

.custom-3 {
  padding: 30px;
  border: 1px solid #9effd8;
  background: #9effd8;
  color: #000;
}

.fw {
  font-weight: 500;
  font-size: 40px;
}

.fw-500 {
  font-weight: 500;
}

.fs16 {
  font-size: 16px;
}

.btn-logout {
  position: fixed;
  right: 10px;
  border: none;
  background: none;
}

.text-end {
  text-align: end;
}

.h-70px {
  height: 70px;
}

.pb-15 {
  padding-bottom: 15rem !important;
}

.icon-1 {
  background-image: src='/media/avatars/icon1.png';
}

.mgb-3 {
  margin-bottom: 3rem;
}

.card {
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 15%) !important;
}

.mapbox {
  flex: 1 1 0%;
}

.preloader {
  width: 50px;
  height: 2px;
  background-color: rgb(240, 240, 240);
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%);
  display: none;
}

.preloader::before {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #999;
  bottom: 20px;
  left: -10px;
  animation: bounce 2s linear infinite;
}

.preloader:after {
  content: '';
  position: absolute;
  top: 0;
  left: -2px;
  height: 2px;
  background-color: red;
  animation: color 2s linear infinite;
}

@keyframes bounce {
  0% {
    bottom: 20px;
    left: -15px;
    width: 10px;
    opacity: 0;
    background: red;
  }

  20% {
    bottom: 0px;
    left: 0;
    width: 10px;
    opacity: 1;
    background: orange;
  }

  22% {
    bottom: 0px;
    left: 0;
    width: 12px;
    height: 8px;
  }

  27% {
    bottom: 0px;
    left: 2px;
    width: 10px;
    height: 10px;
  }

  50% {
    bottom: 20px;
    left: 17px;
    width: 10px;
    height: 10px;
    background: green;
  }

  55% {
    bottom: 20px;
    left: 22px;
    width: 10px;
    height: 10px;
  }

  75% {
    bottom: 0px;
    left: 35px;
    width: 10px;
    height: 10px;
    background: blue;
  }

  80% {
    bottom: 0px;
    left: 35px;
    width: 12px;
    height: 8px;
  }

  82% {
    bottom: 0px;
    left: 35px;
    width: 10px;
    height: 10px;
    opacity: 1;
  }

  100% {
    bottom: 20px;
    left: 50px;
    width: 10px;
    height: 8px;
    opacity: 0;
  }
}

@keyframes color {
  0% {
    width: 0;
    background: red;
  }

  20% {
    width: 10px;
    background: orange;
  }

  50% {
    width: 25px;
    background: green;
  }

  75% {
    width: 45px;
    background: blue;
  }

  100% {
    width: 52px;
    background: purple;
  }
}

.anticon {
  vertical-align: 0 !important;
}

.anticon-right {
  margin-left: 5px;
}

.anticon-filter,
.anticon-audio,
.anticon-plus,
.anticon-download {
  margin-right: 5px;
}

.modal-content-tooltip {
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  /* background-image: url("/media/avatars/tooltip.png"); */
}

.bgr-hd {
  padding: 0px 10px;
  border: 1px solid #000;
  border-radius: 40px;
  background: #cdffe5;
  color: #08a550;
  margin-left: 5px;
  margin-bottom: 0px;
  margin: 0px !important;
}

.bgr-td {
  padding: 0px 10px;
  border: 1px solid #000;
  border-radius: 40px;
  background: #ffb1b1;
  color: #e90000;
  margin-left: 5px;
  margin-bottom: 0px;
  margin: 0px !important;
}

.bgr-cp {
  padding: 0px 10px;
  border: 1px solid #e0f2ff;
  border-radius: 40px;
  background: #e0f2ff;
  color: #009ef7;
  margin-left: 5px;
  margin-bottom: 0px;
  margin: 0px !important;
}

.bgr-tam-dung {
  padding: 0px 10px;
  border: 1px solid #000;
  border-radius: 40px;
  background: #d9d9d9;
  color: #000;
  margin-left: 5px;
  margin-bottom: 0px;
  margin: 0px !important;
}

.icon-search {
  right: 0px;
  margin-right: 1rem;
}

.border-bt {
  border-bottom: inherit;
}

.bt-add-tb {
  padding: 10px 40px;
  background: #1e2ec0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
}

.over-flow {
  overflow: auto;
  height: 81vh;
  background-color: #fff;
}

.upload_file {
  color: #2e1c32;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.fs-1 {
  font-size: 1.2rem !important;
  border-width: 0px !important;
}

.text-danger {
  color: #e90000 !important;
}

.modal-header {
  padding: 1.25rem 1.25rem;
}

.multiSelectContainer ul {
  border: 1px solid #ccc;
  border-radius: 4px;
  display: block;
  margin: 0;
  max-height: 300px !important;
  overflow-y: auto;
  padding: 0;
}

.StatusDaPhat :hover {
  background-color: rgb(255, 251, 210) !important;
}

.btn-move:hover {
  background-color: #009ef7;
  color: #fff;
}

.btn-move:hover .bt-next {
  background-color: #009ef7;
  color: #fff;
}

.TextTitle {
  text-decoration: underline !important;
  color: #1e2ec0 !important;
  cursor: pointer;
}

.p-logo {
  color: rgb(255, 255, 255);
  margin: 0;
  padding-left: 6px;
  font-size: 15px;
  font-weight: 600;
}

body::-webkit-scrollbar {
  width: 5px;
}

body::-webkit-scrollbar-track {
  background: #eff2f5;
}

body::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

@media (min-width: 992px) {

  .container,
  .container-fluid,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl,
  .container-xxl {
    padding: 0 20px;
  }

  .content {
    padding: 20px 0;
  }
}

/* .ant-table-thead>tr>th {
    padding: 15px;
}

.ant-table-tbody>tr>td {
    padding: 15px;
} */

.btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
  padding: 0.75rem 1rem;
}

.card .card-body {
  padding: 1.5rem 2.25rem;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.modal-body {
  padding: 1.25rem;
}

.btn .svg-icon {
  margin-right: 0.25rem;
}

.mb-20px {
  margin-bottom: 20px;
}

.icon-statistic {
  display: block;
  padding: 20px;
  border-radius: 8px;
  width: 90px;
  margin-left: auto;
  margin-right: auto;
}

.span-status {
  border-radius: 11px;
  /* height: 24px; */
  width: 93px;
}

.span-status-active {
  color: #009ef7;
  background-color: #e0f2ff;
}

.span-status-no-active {
  color: #000;
  background-color: #e5ebee;
}

#kt_header {
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
}

.header-fixed.toolbar-fixed .wrapper {
  /* padding-top: calc(65px + var(--kt-toolbar-height)); */
  padding-top: 65px;
}

@media screen and (min-width: 1024px) {
  .kt_header_menu_mb {
    display: none;
  }
}

@media screen and (max-width: 1023px) {
  .breadcrumb {
    display: none;
  }

  /* .btn:not(.btn-outline):not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
        padding: 0 1rem;
    } */

  .bt-button {
    padding: 0 1rem !important;
  }

  .svg-icon-2 {
    /* display: none; */
  }

  .dropdown-toggle {
    padding: 1rem 0 !important;
  }

  .btn.btn-primary.dropdown-toggle:after {
    display: none;
  }

  .text_mb {
    text-align: center;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    /* margin: 3.5rem auto; */
  }
}

.react-datetime-picker__inputGroup__leadingZero {
  padding-top: 8px !important;
}

.react-datetime-picker__inputGroup__day {
  width: 16px !important;
  text-align: right !important;
}

.react-datetime-picker__inputGroup__minute {
  width: 17px !important;
}

.react-datetime-picker__inputGroup__hour {
  width: 16px !important;
  text-align: right !important;
}

.react-datetime-picker__wrapper {
  border: 0 !important;
}

.react-datetime-picker__inputGroup__input:focus-visible {
  outline: none;
}

.input-sercher-loa {
  background-color: #fff !important;
  border-color: #fff !important;
}

.div-treeview-root {
  padding: 0 !important;
  max-width: 252px;
  position: absolute;
  z-index: 998;
  top: 12px;
  left: 12px;
  bottom: 12px;
  background-color: #fff;
}

.div-container-led label {
  height: 25px;
}

.div-container-led div,
.div-container-led textarea {
  height: calc(100% - 25px - 1rem);
}

.div-static div {
  height: calc(100% - 25px - 1rem);
}

/* .youtube-container {
    overflow: hidden;
    width: 60%;
    aspect-ratio: 16/12;
    pointer-events: none;
    margin-left: auto;
    margin-right: auto;
}

.youtube-container video {
    width: 300%;
    height: 100%;
} */

.white-space {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
}

.col-UserGroupName {
  max-width: 180px;
}

.col-Info {
  max-width: 190px;
}

.col-CreateByUser {
  max-width: 120px;
}

.w-110 {
  width: 110px;
}

.btn-mini-treeview {
  border: none;
  border-radius: 0 4px 4px 0;
  background-color: rgb(118 119 130);
  width: 32px;
  height: 32px;
}

.btn-mini-treeview i {
  color: #fff;
}

@media (max-width: 991.98px) {
  .div-treeview-root {
    display: none;
  }

  .div-status-total {
    padding: 12px 12px 0px 12px !important;
  }

  .span-status-spe {
    display: none;
  }

  .div-status-total-map4d {
    padding: 9px 9px 0px 9px !important;
  }

  .btn-3d {
    bottom: 44px !important;
    left: 12px !important;
  }
}

.div-absolute {
  position: absolute;
  z-index: 1;
  display: block !important;
  height: 100%;
  background-color: #fff;
}

.radius-none {
  border-radius: 0 !important;
}

.custom-timeline {
  margin-left: 20px;
}

.rs-icon {
  position: absolute;
  background: #fff;
  top: 0;
  left: -2px;
  border: 2px solid #ddd;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 18px;
  color: #999;
  margin-left: -13px;
  justify-content: center;
  padding: 8px;
}

.rs-timeline-item-content {
  margin-left: 24px;
}

.rs-timeline-item-content p {
  margin: 0;
}

.py-5-0 {
  padding-top: 1.25rem !important;
  padding-bottom: 0rem !important;
}

.py-0-5 {
  padding-top: 0rem !important;
  padding-bottom: 1.25rem !important;
}

.card .card-header .card-title,
.card .card-header .card-title .card-label {
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  color: #2e1c32 !important;
}

tr:nth-child(even) {
  background-color: #f5f5f5;
}

.ant-table-thead>tr>th {
  font-weight: 600;
  font-size: 14px;
  color: #a1a9c7;
  background: #fff;
  text-transform: uppercase;
  text-align: center;
  white-space: inherit;
}

/* .ant-table-cell-fix-left,
.ant-table-cell-fix-right {
  background: none;
} */

.ant-table .ant-table-expanded-row-fixed {
  background: #fff;
}

.chip.false.false {
  white-space: break-spaces;
}

fieldset.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border: 0 !important;
}

#isChecked {
  display: none !important;
}

fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
  border: 0;
}

/*  */
.mapboxgl-popup-content {
  padding: 0 !important;
}

.mapboxgl-marker {
  z-index: 0;
}

.mapboxgl-popup {
  z-index: 1;
}

.mapboxgl-popup-tip {
  opacity: 0;
}

.mapboxgl-popup-content {
  background: #fff0 !important;
}

.switch-view {
  width: 40px;
  height: 40px;
  cursor: pointer;
  bottom: 12px;
  right: 12px;
  position: absolute;
  border: 1px solid gray;
  z-index: 9;
  box-shadow: 0 0 0 2px rgb(0 0 0 / 22%);
}

.navigation-control-map {
  right: 12px;
  /* bottom: 103px; */
  bottom: 64px;
}

.fullscreen-control-map {
  right: 14px;
  bottom: 63px;
}

.div-state {
  display: flex;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ced2d5;
  justify-content: center;
  justify-items: center;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
}

.div-state-div {
  display: flex;
  padding: 6px;
}

.br-r {
  border-right: 1px solid #c2d0d9;
}

.div-state-div span {
  margin-left: 4px;
}

.input-search-device {
  padding: 0.41rem 1rem 0.41rem 2.5rem !important;
  border-radius: 4px;
  border-color: #c2d0d9 !important;
  background-color: #fff !important;
}

.flex-1 {
  flex: 1;
}

.icon-search-device {
  margin: 5px;
}

.tb-popup tr:nth-child(even) {
  background-color: #f5f5f5;
}

.tb-popup {
  max-width: 400px;
}

.btn-cancel {
  border: 1px solid #1e2ec0 !important;
  color: #1e2ec0 !important;
}

.fw-b {
  font-weight: bold;
}

.col-Info-M {
  max-width: 200px;
}

.col-Address-M {
  max-width: 200px;
}

.col-Title-M {
  max-width: 250px;
}

.dropdown-item:hover,
.dropdown-item:focus {
  color: #16192d;
  background-color: #e8f5fe !important;
}

.btn-check:checked+.btn.btn-primary,
.btn-check:active+.btn.btn-primary,
.btn.btn-primary:focus:not(.btn-active),
.btn.btn-primary:hover:not(.btn-active),
.btn.btn-primary:active:not(.btn-active),
.btn.btn-primary.active,
.btn.btn-primary.show,
.show>.btn.btn-primary {
  color: #ffffff;
  background-color: #4f5ddb !important;
}

.btn-check:checked+.btn.btn-light-primary,
.btn-check:active+.btn.btn-light-primary,
.btn.btn-light-primary:focus:not(.btn-active),
.btn.btn-light-primary:hover:not(.btn-active),
.btn.btn-light-primary:active:not(.btn-active),
.btn.btn-light-primary.active,
.btn.btn-light-primary.show,
.show>.btn.btn-light-primary {
  color: #ffffff;
  background-color: #4f5ddb !important;
}

.btn-outline-primary:hover {
  color: #ffffff !important;
  background-color: #4f5ddb !important;
  border-color: #4f5ddb !important;
}

.cancel {
  border: 1px solid #1e2ec0 !important;
  padding: calc(0.75rem - 1px) 1rem !important;
}

.chip {
  background: #dddfe2 !important;
  color: #2e1c32 !important;
}

.nav-line-tabs .nav-item .nav-link {
  color: #2e1c32 !important;
}

.nav-line-tabs .nav-item .nav-link.active {
  color: #1e2ec0 !important;
}

.profile {
  border-bottom: 1px solid #eff2f5;
  padding: 0rem 2.25rem !important;
}

.avatar {
  border: 1px solid #1e2ec0 !important;
  padding: calc(0.75rem - 1px) 1rem !important;
}

.swal2-styled.swal2-cancel {
  border: 1px solid #1e2ec0 !important;
  background-color: #fff !important;
  color: #1e2ec0 !important;
  padding: calc(0.625rem - 1px) 1rem !important;
}

.swal2-styled.swal2-confirm:focus {
  box-shadow: none !important;
}

video {
  background-color: #f1f3f4;
  border-radius: 4px;
}

.breadcrumb .breadcrumb-item {
  display: block !important;
}

.profileOver {
  border-radius: 0px 0px 0.625rem 0.625rem;
  box-shadow: 0px 6px 12px 0px rgb(76 87 125 / 15%) !important;
}

.tbl-user table {
  max-height: 666px;
  display: block;
  overflow-y: scroll;
}

.tbl-user table header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.tbl-user table::-webkit-scrollbar {
  width: 2px;
}

.tbl-user table::-webkit-scrollbar-track {
  background: #eff2f5;
}

.tbl-user table::-webkit-scrollbar-thumb {
  background: #b3b3b3;
}

.div-music {
  justify-content: center;
  padding: 0 40px;
  text-align: center;
  align-items: center;
  margin-bottom: 10px;
}

.mw-500px {
  max-width: 500px;
}

.image_rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.fs-60 {
  font-size: 60px;
  /* border: 2px solid #9899AC; */
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
  width: 64px;
  height: 64px;
}

.icon-play {
  /* padding-left: 3px; */
  /* width: 62px; */
}

.fs-24 {
  font-size: 24px;
  border-radius: 4px;
  display: inline-block;
  cursor: pointer;
  /* background-color: rgba(152, 153, 172, 0.1); */
  padding: 5px;
}

.ul-music {
  background-color: #f4f5f7;
  border-radius: 4px;
  position: absolute;
  bottom: 35px;
  padding: 4px 0;
  right: 0;
  margin: 0;
  color: #000;
}

.ul-music ul {
  padding: 0;
  margin: 0;
}

.ul-music li {
  list-style: none;
  text-align: left;
  padding: 5px 15px;
  display: flex;
  align-items: center;
  min-width: max-content;
  cursor: pointer;
}

.ul-music li:hover {
  background-color: #e7e9ed54;
}

.div-list-file {
  background-color: #f4f5f7;
  border-radius: 4px;
  position: absolute;
  bottom: 35px;
  padding: 4px 0;
  right: 0;
  margin: 0;
}

.div-list-file ul {
  padding: 0;
  margin: 0;
}

.div-list-file li {
  list-style: none;
  text-align: left;
  padding: 5px 15px;
  align-items: center;
  min-width: max-content;
  cursor: pointer;
}

.div-list-file li:hover {
  background-color: #e7e9ed54;
}

.bg-1 {
  background-image: src='/media/avatars/Group208.svg';
}

.bg-2 {
  background-image: src='/media/avatars/Group209.svg';
}

.white {
  color: #fff;
}

.black {
  color: #000 !important;
}

.icon-statistic-2 {
  display: block;
  border-radius: 8px;
  width: 90px;
}

.h-100 {
  height: 100%;
}

.icon-statistic-3 {
  display: block;
  border-radius: 8px;
  /* width: 51.5px; */
}

.pl-1 {
  padding-left: 1rem;
}

.form-control.form-control-solid.disable {
  background-color: #eaedef !important;
  border-color: #eaedef !important;
  color: #1e2ec0 !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.form-select.form-select-solid.fw-bolder.disable {
  background-color: #eaedef !important;
  border-color: #eaedef !important;
  color: #1e2ec0 !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.timeline.timeline-4 .timeline-items .timeline-item:after {
  position: absolute;
  content: '';
  width: 0;
  height: 0;
  top: 3rem;
  left: 100%;
  margin-left: -3rem;
  border-left: solid 10px #f3f6f9;
  border-bottom: solid 17px transparent;
  border-right: solid 17px transparent;
  border-top: solid 17px transparent;
}

.rs-timeline-align-left .rs-timeline-item {
  padding-left: 0px !important;
}

.rs-timeline-item:last-child .rs-timeline-item-tail {
  bottom: auto;
  height: 90px !important;
}

.rs-timeline-item:first-child .rs-timeline-item-tail {
  top: 10px !important;
}

.rs-timeline-item:only-child .rs-timeline-item-tail {
  display: block !important;
}

.rs-timeline {
  margin-bottom: 5px !important;
}

.rate-active {
  background-color: #e7e9ed;
}

.div-volume {
  position: absolute;
  bottom: 30%;
  left: 62%;
  /* transform: translateX(-50%); */
  background-color: #f4f5f7;
  width: 85px;
  padding-top: 10px;
  border-radius: 4px 4px 0 0;
  display: none;
  box-shadow: 0 0 20px 0 rgba(76, 87, 125, 0.15);
}

.div-volume:hover {
  display: block;
}

.div-volume div:nth-child(2) {
  transform: translate(-54%, -50%) !important;
}

.icon-volume:hover~.div-volume {
  display: block;
}

.fs-18 {
  font-size: 18px;
  margin-right: 10px;
}

.p-l-44 {
  padding-left: 44px !important;
}

.symbol.symbol-lg-160px.symbol-fixed>img {
  width: 120px !important;
  height: 120px !important;
  max-width: none;
}

.map-abc>div:nth-child(2) {
  width: 100% !important;
  z-index: 1 !important;
}

#kt_content {
  z-index: 1;
}

.map-db>div:nth-child(1) {
  height: 100% !important;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 15%) !important;
  border-radius: 0.625rem;
  overflow: hidden;
  min-height: 666px;
}

.pd-30 {
  padding: 30px;
}

.pd-30s {
  padding: 24px;
}

@media (max-width: 1366px) {
  .pd-30 {
    padding: 15px;
  }

  .pd-30s {
    padding: 22px;
  }
}

@media (max-width: 1365px) {
  .icon-statistic-2 {
    width: auto !important;
  }

  .mb-mt-5 {
    margin-top: 1.25rem !important;
  }
}

.center {
  margin-bottom: auto;
  margin-top: auto;
}

.box-db {
  padding: 10px;
  background: #eff2f5;
  /* border-radius: 4px; */
}

.fs-18px {
  font-size: 18px;
}

.fs-11 {
  font-size: 11px;
}

.p-15-0 {
  padding: 1.5rem 0 !important;
}

.canvasjs-chart-credit {
  display: none;
}

.p-125-0 {
  padding: 1.25rem 0 !important;
}

.aside.aside-dark {
  background: #080925 !important;
  z-index: 9999;
}

.aside.aside-dark .aside-logo {
  background: linear-gradient(182.16deg, #0092e4 100%, #002689 0%) !important;
  z-index: 9999;
}

.aside-dark .menu .menu-item .menu-link.active {
  background-color: #3c3e4f !important;
}

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here),
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {
  background-color: #4f5ddb !important;
  /* font-weight: 600 !important; */
}

.aside-dark .menu .menu-item .menu-link .menu-title {
  color: #fff;
}

.aside-dark .menu .menu-item .menu-link.active .menu-title {
  font-weight: 700 !important;
}

/* .aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-title,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-title {
    font-weight: 600 !important;
} */

.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon,
.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon,
.aside-dark .menu .menu-item.hover:not(.here)>.menu-link:not(.disabled):not(.active):not(.here) .menu-icon i,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon .svg-icon,
.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .menu-icon i {
  color: #fff;
}

.aside-dark .menu .menu-item .menu-link .menu-icon,
.aside-dark .menu .menu-item .menu-link .menu-icon .svg-icon,
.aside-dark .menu .menu-item .menu-link .menu-icon i {
  color: #fff;
}

.aside-dark .menu .menu-item .menu-link.active .menu-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon .svg-icon,
.aside-dark .menu .menu-item .menu-link.active .menu-icon i {
  color: #fff;
}

.rotate-180 {
  color: #fff !important;
}

.ant-table table {
  width: 100% !important;
}

.w-18 {
  width: 18px;
}

.pd-0 {
  padding: 0 !important;
}

.div-status-total {
  padding: 12px 12px 0px 277px;
  /* z-index: 1; */
}

.mapboxgl-ctrl-bottom-right {
  display: none;
}

.aside-dark .menu .menu-item .menu-link .menu-arrow:after {
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 8 9%27 fill=%27%23ffffff%27%3e%3cpath fill-rule=%27evenodd%27 clip-rule=%27evenodd%27 d=%27M5.93537 4.57889C6.03839 4.77912 6.0191 5.0363 5.87137 5.21403L2.87153 8.82282C2.68598 9.04603 2.36951 9.06026 2.16468 8.8546C1.95985 8.64893 1.94422 8.30126 2.12977 8.07804L4.80594 4.85863L2.15586 1.93583C1.96104 1.72096 1.96165 1.37314 2.15722 1.15895C2.35279 0.944757 2.66927 0.945311 2.86409 1.16018L5.85194 4.45551C5.8859 4.49296 5.91371 4.53459 5.93537 4.57889Z%27/%3e%3c/svg%3e') !important;
}

.aside.aside-dark .aside-logo {
  background: none !important;
}

.span-title-maf {
  width: 100%;
  text-align: center;
}

.treemap {
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  color: #434658 !important;
}

.prv-avatar {
  position: absolute;
  left: 1.5rem;
  right: 1.5rem;
  bottom: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.ant-table {
  color: #2e1c32 !important;
}

.update:hover {
  color: #ffffff !important;
  background-color: #4f5ddb !important;
}

.navigation-control-map-dashboard {
  right: 12px;
  bottom: 50px;
}

.fullscreen-control-map-dashboard {
  right: 12px;
  bottom: 11px;
}

.canvasjs-chart-credit {
  display: none !important;
}

.search-location {
  position: absolute;
  top: 8px;
  left: 8px;
  right: 8px;
  z-index: 1;
}

.btn-show-report {
  padding: 1rem;
}

@media (max-width: 767.98px) {
  .btn-show-report {
    margin-top: 10px !important;
  }

  .input-search-device {
    padding: 0.55rem 1rem 0.55rem 2.5rem !important;
  }

  .div-logo-login h4 {
    font-size: 14px;
    line-height: 22px;
  }

  .over-flow {
    height: 70vh;
  }

  .div-login {
    padding: 2rem 1rem 1.5rem !important;
  }
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.selectChart {
  font-weight: 400 !important;
  color: #2e1c32 !important;
}

#combo-box-demo {
  padding: 0 !important;
  font-size: 14px !important;
  height: 28px !important;
}

.search-location>div>div>div {
  padding: 7px !important;
}

.search-location-report>div>div>div {
  padding: 7.5px 9px !important;
}

.search-location-report #combo-box-demo {
  font-weight: 600 !important;
}

.label-fillter-report {
  margin: 0;
  float: right;
}

.modal-header h2 {
  font-size: 1.5rem !important;
  line-height: 1.2 !important;
}

.tab-link {
  color: #000;
}

.tab-link:hover,
.tab-link:focus {
  color: #4f5ddb;
  border-bottom: 2px solid #4f5ddb;
}

.tab-link.active {
  color: #1e2ec0;
  border-bottom: 2px solid #1e2ec0;
}

h1,
.h1 {
  font-size: calc(1.3rem + 0.6vw) !important;
  line-height: normal;
}

.form-select.form-select-solid {
  color: #2e1c32 !important;
}

/* .aside-dark .menu .menu-item.show > .menu-link {
  background-color: #009ef7 !important;
} */

/* .aside-dark .menu .menu-item.here > .menu-link {
  background-color: #009ef7 !important;
  font-weight: 600 !important;
} */

.aside-menu .menu .menu-sub:not([data-popper-placement])>.menu-item>.menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement])>.menu-item>.menu-link,
.aside-menu .menu .menu-sub:not([data-popper-placement])>.menu-inner>.menu-item>.menu-content,
.aside-menu .menu .menu-sub:not([data-popper-placement])>.menu-inner>.menu-item>.menu-link {
  padding-left: 25px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  background-color: #eaedef !important;
  border-color: #eaedef !important;
  color: #1e2ec0 !important;
  transition: color 0.2s ease, background-color 0.2s ease;
}

.highcharts-credits {
  display: none;
}

.col-group-name {
  max-width: 150px;
}

.cursor-pointer {
  cursor: pointer;
}

.loa-ket-noi:hover {
  background: antiquewhite !important;
  transition: 0.4s;
}

.loa-dang-phat:hover {
  background: darkseagreen !important;
  transition: 0.4s;
}

.loa-su-co:hover {
  background: darksalmon !important;
  transition: 0.4s;
}

.loa-cho-phat:hover {
  background: deepskyblue !important;
  transition: 0.4s;
}

.bang-dien-tu:hover {
  background: darkgray !important;
  transition: 0.4s;
}

.icon-tt {
  margin: 1px 5px 3px 0;
  width: 25px;
  height: 25px;
}

h3,
.h3 {
  font-size: 1.35rem;
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  background: #aeb6bd57;
}

.h-24px {
  height: 24px !important;
}

.w-48px {
  width: 48px !important;
}

.icon-setting .form-check.form-check-solid .form-check-input {
  background-color: #fbab10 !important;
}

.icon-setting .form-check.form-check-solid .form-check-input:checked {
  background-color: #009ef7 !important;
}

.div-volume-map {
  position: relative;
}

.TimeSlider {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.red-text {
  color: #e90000;
}

.modal-content-tooltip-new {
  background-color: rgb(0 0 0 / 0%);
  color: #fff;
}

.mf-info-window-content {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 4px;
}

.mf-info-window-content p {
  font-family: inherit !important;
}

.mf-info-window-content:before {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
  z-index: 1;
}

.map4d-dashboard {
  width: 100%;
  height: calc(100vh - 5rem);
  position: relative;
  border-radius: 0.625rem;
}

.div-do-bao-phu {
  position: absolute;
  top: 57px;
  left: 8px;
  background-color: #fff;
  padding: 15px 10px 10px;
  font-size: 13px;
}

.div-do-bao-phu .o-vuong {
  width: 24px;
  height: 24px;
}

.div-do-bao-phu .phan-tram {
  display: block;
  margin-top: -10px;
  margin-bottom: 16px;
}

.div-status-total-map4d {
  padding: 12px 12px 0px 277px;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
}

.p-select {
  padding: 4px 11px;
}

.align-items-stretch {
  align-items: center !important;
}

.btn-3d {
  position: absolute;
  bottom: 83px;
  right: 83px;
  font-weight: bold;
  font-size: 12px;
  width: 29px;
  height: 29px;
  background-color: #fff;
  color: #1e76bd;
  box-shadow: 0px 0px 4px #666;
}

.btn-treview-mobile {
  position: absolute;
  left: 10px;
  top: 50px;
  z-index: 1;
}

.btn-treview-mobile button {
  background-color: #fff;
  width: 29px;
  height: 29px;
  box-shadow: 0px 0px 4px #666;
}

.btn-treview-mobile button i {
  color: #1e76bd;
}

.div-absolute-map4d {
  position: absolute;
  z-index: 1;
  display: block !important;
  height: 92.5%;
  background-color: #fff;
  top: 48px;
  left: 9px;
}

.icon-bell {
  font-size: 21px !important;
  margin-top: 9px !important;
}

.div-do-bao-phu-dtt {
  top: 57px;
  right: 13px;
  z-index: 1;
  border: 1px solid #ced2d5;
  border-radius: 4px;
  left: auto;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:hover,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:active {
  color: #fff !important;
  border-color: #4f5ddb !important;
  background: #4f5ddb !important;
}

.toast-notification {
  position: absolute;
  top: 12px;
  right: 18px;
  min-width: 200px;
  z-index: 1;
}

.icon-bell-toast {
  font-size: 16px;
  margin-right: 4px;
  color: #fff;
}

.toast-header {
  background-color: #009ef7;
  color: #fff;
}

.btn-close {
  display: none;
  /* margin-right: 0px !important;
    background-color: #fff; */
}

.icon-x {
  color: #fff;
  font-size: 18px;
  margin-left: 4px;
  background-color: #0000001f;
  border-radius: 4px;
  cursor: pointer;
}

.info-polygon {
  position: absolute;
  /* top: 50%;
    left: 50%; */
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  padding: 11px;
  border-radius: 4px;
}

.border-bottom-1 {
  border-bottom: 1px solid #eee;
}

.notification-new {
  background-color: aliceblue;
}

.border-bottom-1:hover {
  background-color: ghostwhite;
  transition: 0.4s;
}

.col-Header {
  max-width: 255px;
}

.mh-525px {
  max-height: 525px !important;
}

.nav-tabs .nav-item {
  z-index: 1;
  font-weight: bold;
}

.btn-back {
  color: #009ef7 !important;
}

.bi-clock-history {
  font-size: 18px !important;
  padding: 0;
}

.bi-arrow-clockwise {
  font-size: 18px !important;
  padding: 0;
}

.div-select-city {
  display: flex;
  background-color: #f5f8fa;
  align-items: baseline;
  padding-left: 12px;
}

.color-black {
  color: #000000a1 !important;
}

.rotate-90 {
  transform: rotate(90deg);
}

.div-logo {
  flex: 1;
}

.div-logo a {
  display: flex;
  align-items: center;
}

.div-logo img {
  width: 50px;
}

.div-logo p {
  color: #fff;
  font-weight: bold;
  flex: 1;
}

.text-logo-login {
  color: #fff;
  max-width: 333px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 10px;
}

.div-logo-login {
  display: flex;
  align-items: center;
}

.input-range__track {
  height: 0.5rem !important;
}

.input-range__label-container {
  font-size: 1rem !important;
}

.col-Description {
  max-width: 400px;
}

.icon-action {
  cursor: pointer;
  padding: 5px 7px;
  color: #1e2ec0;
  background: #e0f2ff;
  border-radius: 5px;
}

.float-right {
  float: right;
}

.ward-name {
  max-width: 145px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

.search-location-new {
  margin-top: -10px;
  margin-bottom: 10px;
}

.select-city-dashboard {
  position: absolute;
  z-index: 1;
  top: 8px;
  left: 2px;
}

.p-empty {
  color: #a4a8b9;
  text-align: center;
  padding: 10px;
}

.check-input {
  height: 1.55rem;
  width: 1.55rem;
  background-size: 70% 70%;
  margin-right: 10px;
}

.m-r-10 {
  margin-right: 10px;
}

.update-time {
  min-width: 160px;
}

/* @media screen and (min-width: 1680px) {
    .div-login {
        padding-bottom: 15rem !important;
    }
} */

.min-width {
  min-width: 130px;
}

.datetime-input {
  padding: 12.6px 14px;
}

.forgot_pass {
  color: #009ef7;
}

.forgot_pass:hover {
  color: #0082cb;
  cursor: pointer;
}

.input-radio {
  height: 16px;
  width: 16px;
  margin-right: 5px;
}

.div-login {
  justify-content: center;
  align-items: center;
  padding: 4rem 1rem 1.5rem;
}

.span-group {
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
}

.phatthanh {
  display: flex;
  align-items: center;
}

.mw-1100px {
  max-width: 1100px;
}

.marquee-custome {
  bottom: 30px;
  background-color: #fff;
}

.input-color {
  margin-left: 6px;
  border: solid 1px #000;
  width: 32px;
}

.input-color-code {
  margin-left: 6px;
  max-width: 120px;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  height: 0.77em !important;
}

.swal2-title {
  line-height: 1.5;
}

.center-wave {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wave {
  width: 5px;
  height: 100px;
  background: linear-gradient(45deg, #009ef7, #fff);
  margin: 10px;
  animation: wave 1s linear infinite;
  border-radius: 20px;
}

.wave:nth-child(2) {
  animation-delay: 0.1s;
}

.wave:nth-child(3) {
  animation-delay: 0.2s;
}

.wave:nth-child(4) {
  animation-delay: 0.3s;
}

.wave:nth-child(5) {
  animation-delay: 0.4s;
}

.wave:nth-child(6) {
  animation-delay: 0.5s;
}

.wave:nth-child(7) {
  animation-delay: 0.6s;
}

.wave:nth-child(8) {
  animation-delay: 0.7s;
}

.wave:nth-child(9) {
  animation-delay: 0.8s;
}

.wave:nth-child(10) {
  animation-delay: 0.9s;
}

@keyframes wave {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

.volume-speaker {
  margin-right: 5px;
  display: flex;
  align-items: center;
}

.live-stream {
  float: right;
  background: red;
  color: #fff;
  padding: 0 4px;
  border-radius: 4px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  animation: live01 1.8s infinite alternate;
}

@keyframes live01 {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}

.mw-120px {
  max-width: 120px;
}

.mf-info-window-anchor:after {
  border-top-color: rgba(0, 0, 0, 0.8) !important;
}

.progress-6 {
  width: 120px;
  height: 15px;
  border-radius: 20px;
  color: #009ef7;
  border: 2px solid;
  position: relative;
}

.progress-6::before {
  content: '';
  position: absolute;
  margin: 2px;
  inset: 0 100% 0 0;
  border-radius: inherit;
  background: currentColor;
  animation: p6 2s infinite;
}

@keyframes p6 {
  100% {
    inset: 0;
  }
}

.div-progress {
  width: 200px;
  height: 15px;
  border-radius: 20px;
  color: #009ef7;
  border: 2px solid;
  position: relative;
}

.div-progress div {
  height: 7px;
  width: 0;
  margin: 2px;
  border-radius: inherit;
  background: currentColor;
}

.span-progress {
  color: #009ef7;
  margin: 0 5px;
  font-size: 12px;
}

.input-range {
  width: 90% !important;
  margin: 0 5%;
}

.input-range__slider {
  margin-top: -0.75rem !important;
}

.file-active {
  background-color: #e7e9ed;
  color: #009ef7;
}

.file-name {
  min-width: auto !important;
  max-width: 400px;
}

.loader {
  right: 0;
  top: -3px;
  left: 0;
  border-radius: 50%;
  position: absolute;
  transform: rotate(45deg);
  box-sizing: border-box;
  display: none;
}

.loader::before {
  content: '';
  position: absolute;
  box-sizing: border-box;
  /* inset: -10px; */
  border-radius: 50%;
  border: 2px solid #009ef7;
  animation: prixClipFix 2s infinite linear;
  width: 24px;
  height: 24px;
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.logo-login {
  max-width: 300px;
  max-height: 200px;
}

.add {
  padding: 0 !important;
  border: 1px solid #1e2ec0 !important;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  line-height: 22px;
}

.minus {
  padding: 0 !important;
  border: 1px solid #009ef7 !important;
  border-radius: 50%;
  font-size: 22px;
  width: 25px;
  height: 25px;
  line-height: 5px;
}

.download_file_doc {
  display: flex;
  left: 91.8%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 94%;
}

.download_file_img {
  display: flex;
  left: 91.8%;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 91%;
}

.mw-75 {
  max-width: 75%;
}

.mw-65 {
  max-width: 65%;
}

.text-tranform {
  height: 560px;
  text-align: justify;
}

.text-tranform::-webkit-scrollbar {
  width: 5px;
}

.text-tranform::-webkit-scrollbar-track {
  background: #fff;
}

.text-tranform::-webkit-scrollbar-thumb {
  background: #eff2f5;
}

.doc-editor {
  height: 600px !important;
}

.viewer-toolbar-right .viewer-toolbar-item:nth-child(4) {
  display: none;
}

.modal-content {
  margin-top: 5rem !important;
}

.ScrolTimePlay {
  max-height: 225px;
  overflow: auto;

  .volume {
    padding-top: 10px;
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: space-between;

    .icon-volume {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.border_0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.pg-viewer-wrapper {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 600px;
  max-width: 700px;
}

.document_view {
  font-family: sans-serif;
  text-align: center;
  height: 99%;
}

.ant-picker-dropdown {
  z-index: 1056;
}

.ant-picker-large .ant-picker-input>input {
  font-size: 14px !important;
}

.form-control .MuiInputBase-input {
  height: 0.77em !important;
}

.ant-table-content::-webkit-scrollbar:horizontal {
  height: 10px !important;
}

.ant-table-content::-webkit-scrollbar-thumb:horizontal {
  border-radius: 5px;
}

.ant-btn-primary {
  color: #fff;
  border-color: #1e2ec0 !important;
  background: #1e2ec0 !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.btn-active {
  color: #fff;
  background-color: #52c41a !important;
}

.btn-error {
  color: #fff;
  background-color: #f5222d !important;
}

.btn-active:hover {
  color: #fff;
  background-color: #95de64 !important;
}

.btn-error:hover {
  color: #fff;
  background-color: #ff7875 !important;
}

.ant-btn-default:hover,
.ant-btn-default:focus {
  color: #1e2ec0 !important;
  border-color: #1e2ec0 !important;
  background: #fff;
}

.ant-input:hover,
.ant-input:focus,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper:focus,
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #1e2ec0 !important;
  box-shadow: none;
}

.ant-modal-header {
  border-radius: 12px 12px 0 0 !important;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #1e2ec0 !important;
  box-shadow: none;
}

.ant-input-number:focus,
.ant-input-number:hover {
  border-color: #1e2ec0 !important;
  box-shadow: none;
}

.ant-slider-track {
  background-color: #1e2ec0 !important;
}

.ant-slider-handle {
  background-color: #1e2ec0 !important;
}

.al-center {
  align-items: center;
}

.select-dashboard:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 8px !important;
  background-color: #f5f8fa !important;
  border-color: #f5f8fa !important;
  width: 250px !important;
  color: #000 !important;
}

.select-dashboard:not(.ant-select-disabled):focus .ant-select-selector,
.select-dashboard:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #f5f8fa !important;
  box-shadow: none;
}

.modal-device .ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
  margin-top: 6px !important;
  margin-left: -4px !important;
  cursor: auto;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f5f8fa !important;
  border: none !important;
}

.broadcastTime {
  min-width: 200px;
  max-width: 200px;
  white-space: normal !important;
}

.display-content-help {
  display: flex;
}

.link-help {
  color: blue;
  cursor: pointer;
}

.ant-pagination {
  display: flex;
}

.ant-pagination-total-text {
  margin-right: auto !important;
}

.ant-table-thead>tr>th {
  background-color: #0F1D9B !important;
  color: white !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: white;
  background-color: #0136AA;
  border-color: #0136AA #0136AA #ffffff;
}

.ant-table-thead>tr>th {
  white-space: nowrap;
}

.css-rhmlg1-MuiTypography-root-MuiDayCalendar-weekDayLabel,
.css-cyfsxc-MuiPickersCalendarHeader-labelContainer {
  font-size: medium !important;
  font-weight: bold;
}

.css-1jsy6pn-MuiButtonBase-root-MuiPickersDay-root,
.css-qa7bje-MuiButtonBase-root-MuiPickersDay-root {
  font-size: small !important;
}

.microphone-input {
  height: 300px;
  display: flex;
  align-items: center;
  background-color: #080213;
}

.volume-bars {
  display: contents;
  align-items: flex-end;
  gap: 4px;
}

.volume-bar {
  width: 300px;
  /* Điều chỉnh kích thước cột theo ý muốn */
  /* background-color: #1E2EC0; */
  height: 2px;
  border-radius: 5px;
  transition: height 0.1s, background-color 0.1s;
}

.btn-mic {
  position: absolute;
  left: 44%;
  top: 60%;
}

.custom-audio-whep {
  position: absolute;
  left: 36%;
  top: 70%;
}

.count-down {
  position: absolute;
  left: 83%;
  top: 6%;
}

.ant-statistic-content-value {
  color: white;
}

.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
  border-radius: 8px;
  height: 24px;
  margin-top: 10px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf50;
  text-align: center;
  line-height: 24px;
  color: black;
  border-radius: 8px;
}

.div-qr-code{
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

.color-authen{
  font-size: large;
  font-weight: bold;
  color: aliceblue;
}

.btn-end{
  display: flex;
  justify-content: end;
}

/* ThietBiNCCModal.css */
.responsive-modal .ant-modal {
  width: 100% !important;
}

@media (min-width: 576px) {
  .responsive-modal .ant-modal {
    width: 80% !important;
  }
}

@media (min-width: 768px) {
  .responsive-modal .ant-modal {
    width: 70% !important;
  }
}

@media (min-width: 992px) {
  .responsive-modal .ant-modal {
    width: 60% !important;
  }
}

@media (min-width: 1200px) {
  .responsive-modal .ant-modal {
    width: 50% !important;
  }
}

@media (min-width: 1600px) {
  .responsive-modal .ant-modal {
    width: 40% !important;
  }
}

.warning-notification {
  overflow: hidden;
  white-space: nowrap;
  box-sizing: border-box;
  animation: shake 0.5s ease-in-out 3; /* Rung 5 lần */
  font-weight: bold;
  padding: 15px;
  text-align: center;
  position: fixed;
  top: 5%;
  left: 30%; 
  z-index: 1000;

  .ant-alert-message {
    color: red;
  }
  .ant-alert-content {
    color: red;
  }
}
@keyframes shake {
  0%, 100% { transform: translateX(0); }
  10%, 90% { transform: translateX(-5px); }
  20%, 80% { transform: translateX(5px); }
  30%, 50%, 70% { transform: translateX(-5px); }
  40%, 60% { transform: translateX(5px); }
}

/* .Piece{
  &{
    position: relative ;
    background-color:#4682b4;
    width:150px;
    height:75px;
  }
  &-phrase{
    position:absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    color:white;
  }
} */
/* .modal-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  min-width: 500px !important;
} */